export const calculateDiscountPercentage = (originalPrice, sellingPrice) => {
  if (Number(originalPrice) <= 0 || Number(sellingPrice) <= 0) {
    // Handle invalid input
    return 0;
  }

  // Calculate discount percentage
  const discountPercentage =
    ((Number(originalPrice) - Number(sellingPrice)) / Number(originalPrice)) *
    100;

  return Math.round(discountPercentage);
};
