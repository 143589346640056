import React from "react";
import "./../../assets/scss/error.scss";

const Error = ({ msg }) => {
  return (
    <div className="error_wrapper">
      <h6>{msg}</h6>
    </div>
  );
};

export default Error;
