import React from "react";
import "./../assets/scss/demopage.scss";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
const DemoPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="app_wrapper p-2">
        <div className="row g-0 g-sm-2 p-2 p-sm-3">
          <div className="col-6">
            <div className="text_wrapper">
              <p>
                Hey there! Our web app is like a sneak peek right now, but get
                ready because once we tweak it just for you, it's going to be
                awesome. You'll be able to make your own account, Log in and
                out, toss things in your cart, check your orders, and a bunch
                more cool stuff.
              </p>
              <p>
                But hold on, there's a bonus! You'll also get an admin panel.
                It's like the backstage pass where you can add, check, update,
                or delete products. Admins can also do superhero stuff like
                managing users, overseeing orders, receiving quotations, and
                handling various other tasks.
              </p>
              <p>
                Why pick this app? It's like having your store online, making
                things super easy for your customers. When they want to buy
                something, no need to pay upfront. The app shoots a price quote
                to the admin panel and the customer. You both can chat about it,
                bargain a bit, agree on a final price, and then go on with
                business as usual.
              </p>
              <p>
                We're super pumped to tweak this website just right for you,
                making it not just easy but totally your style!
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="text_wrapper">
              <p>
                नमस्ते! हमारा वेब ऐप अभी तो एक झलक है, लेकिन तैयार रहना क्योंकि
                जब हम इसे बस आपके लिए बनाएंगे, तो यह बहुत शानदार होने वाला है।
                आप अपना खाता बना सकेंगे, लॉग इन और आउट कर सकेंगे, अपनी खरीदी को
                कार्ट में डाल सकेंगे, अपने ऑर्डर की जाँच कर सकेंगे, और इसके
                अलावा भी कई सारी शानदार चीजें कर सकेंगे।
              </p>
              <p>
                लेकिन रुको, इसमें एक बोनस भी है! इसके साथ ही आपको एक ऐडमिन पैनल
                मिलेगा। यह वह बैकस्टेज पास है जहां आप प्रोडक्ट्स जोड़ सकते हैं,
                चेक कर सकते हैं, अपडेट या डिलीट कर सकते हैं। ऐडमिन यूजर्स को
                हैंडल करने, ऑर्डर्स की निगरानी रखने, कोटेशन प्राप्त करने और
                विभिन्न अन्य कार्यों का सुपरहीरो स्टाइल मैनेज करने जैसे काम कर
                सकते हैं।
              </p>
              <p>
                इस एप्लिकेशन को क्यों चुनें? इसे अपनी दुकान का ऑनलाइन संस्करण
                समझो, जो आपके ग्राहकों के लिए सब कुछ बहुत आसान बना देता है। जब
                वे कुछ खरीदना चाहते हैं, उन्हें पहले से पैसे नहीं देने पड़ते। इस
                एप्लिकेशन ने एक मूल्य कोट ऐडमिन पैनल और ग्राहक दोनों को भेजता
                है। आप इस पर बातचीत कर सकते हैं, अंत में एक अंतिम मूल्य पर सहमति
                कर सकते हैं, और फिर खरीददारी के साथ आगे बढ़ सकते हैं।
              </p>
              <p>
                हम इस वेबसाइट को बिल्कुल आपकी आवश्यकताओं के हिसाब से ठीक करने के
                लिए उत्सुक हैं, ताकि यह न केवल आसान हो बल्कि पूरी तरह से आपकी
                तरह बनी हो!
              </p>
            </div>
          </div>
          <div className="col-12">
            <div className="demo_back_btn_container mb-3">
              <button
                className="demo_back_btn button_primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoPage;
