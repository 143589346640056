import React, { useEffect, useState } from "react";
import "./../assets/scss/productdetail.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { productIdStr } from "../assets/js/variables";

import api from "./../assets/axios/api";
import Error from "./utils/Error";
import Loader from "./utils/Loader";
import { calculateDiscountPercentage } from "../assets/js/calculateDiscountPercentage";

const ProductDetail = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const productId = searchParams.get(productIdStr);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const [currImage, setCurrImage] = useState(0);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(0);

  const [selectedSizeError, setSelectedSizeError] = useState(null);
  const [selectedQuantityError, setSelectedQuantityError] = useState(null);

  useEffect(() => {
    if (selectedSizeError) {
      setTimeout(() => {
        setSelectedSizeError(null);
      }, 1200);
    }

    if (selectedQuantityError) {
      setTimeout(() => {
        setSelectedQuantityError(null);
      }, 1200);
    }
  }, [selectedQuantityError, selectedSizeError]);

  const handleCancelPopUp = () => {
    searchParams.delete(productIdStr);
    setSearchParams(searchParams);
  };

  const handleChangeVariant = (variantId) => {
    setCurrImage(0);
    setSelectedSize(null);

    searchParams.delete(productIdStr);
    searchParams.append(productIdStr, variantId);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    // const cancelTokenSource = axios.CancelToken.source();
    const fetchData = async () => {
      setLoading(true);
      try {
        api
          .get(`products/get_details/${productId}`)
          .then((response) => {
            console.log(response.data);
            setData(response.data);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setError("Something went wront not able to fetch data");
          });
      } catch (error) {
        setLoading(false);
        setError("Something went wront not able to fetch data");
      }
    };
    fetchData();
  }, [productId]);

  const handleAddToCart = () => {
    if (!selectedSize) {
      setSelectedSizeError("Please select size!");
    } else if (!selectedQuantity) {
      setSelectedQuantityError("Please select quantity!");
    } else {
      navigate("/demo");
    }
  };

  if (loading) {
    return (
      <div className="product_detail_container">
        <div className="cross_container" onClick={handleCancelPopUp}></div>
        <div className="product_detail_wrapper">
          <div className="product_detail_inner_wrapper">
            <div className="main">
              <div className="row">
                <div className="col-12">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="product_detail_container">
        <div className="cross_container" onClick={handleCancelPopUp}></div>
        <div className="product_detail_wrapper">
          <div className="product_detail_inner_wrapper">
            <div className="main">
              <div className="row">
                <div className="col-12">
                  <Error msg={error} />;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (data) {
    const { product, otherSimmilarVariants } = data;
    return (
      <div className="product_detail_container">
        <div className="cross_container" onClick={handleCancelPopUp}></div>
        <div className="product_detail_wrapper">
          <div className="product_detail_inner_wrapper">
            <div className="main">
              <div className="row">
                <div className="col-md-7">
                  <div className="row g-2">
                    <div className="order-2 order-sm-1 col-sm-3 col-lg-4">
                      {/* <div className=""> */}
                      <div className="img_list_container row g-2 g-sm-4">
                        {product.variant[0].images.map((item, i) => (
                          <div key={i} className="col-3 col-sm-12 col-lg-6">
                            <div
                              className={`image_box ${
                                currImage === i ? "active" : "cursor"
                              }`}
                              style={{
                                backgroundImage: `url(${item})`,
                              }}
                              onClick={() => setCurrImage(i)}
                            ></div>
                          </div>
                        ))}
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="order-1 order-sm-2 col-sm-9 col-lg-8">
                      <div className="img_main_container px-sm-4">
                        <div
                          className="image_box"
                          style={{
                            backgroundImage: `url(${product.variant[0].images[currImage]})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 my-4 my-md-0">
                  <div className="content_container">
                    <p className="name">{product.title}</p>
                    <p className="discription">{product.description}</p>
                    <div className="divider"></div>
                    <p className="price_wrapper">
                      <span className="price">
                        &#8377;{product.variant[0].discountedPrice}
                      </span>
                      <span className="original_price">
                        &#8377;{product.variant[0].price}
                      </span>
                      <span className="discount">{`(${calculateDiscountPercentage(
                        product.variant[0].price,
                        product.variant[0].discountedPrice
                      )}%OFF)`}</span>
                    </p>
                    <div className="divider mb-4"></div>

                    {otherSimmilarVariants.length ? (
                      <>
                        <p className="heading">MORE COLORS</p>
                        <div className="img_color_container">
                          {otherSimmilarVariants.map((item, i) => (
                            <div
                              key={i}
                              className="image_box cursor"
                              style={{
                                backgroundImage: `url(${item.image})`,
                              }}
                              onClick={() => handleChangeVariant(item.id)}
                            ></div>
                          ))}
                        </div>
                      </>
                    ) : null}

                    {selectedSizeError ? (
                      <p className="heading error_msg">{selectedSizeError}</p>
                    ) : (
                      <p className="heading">SELECT SIZE</p>
                    )}
                    <div className="select_size_container">
                      {product.variant[0].sizes.map((item, i) => (
                        <div
                          key={i}
                          className={`size_box ${
                            selectedSize === item.details.size
                              ? "selected_box"
                              : "cursor"
                          }`}
                          onClick={() => setSelectedSize(item.details.size)}
                        >
                          {item.details.size}
                        </div>
                      ))}
                    </div>

                    {selectedQuantityError ? (
                      <p className="heading error_msg">
                        {selectedQuantityError}
                      </p>
                    ) : (
                      <p className="heading">SELECT QUANTITY</p>
                    )}
                    <div>
                      <select
                        onChange={(e) => {
                          setSelectedQuantity(e.target.value);
                        }}
                      >
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={8}>8</option>
                        <option value={10}>10</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                    <button
                      className="button_primary"
                      onClick={handleAddToCart}
                    >
                      ADD TO CART
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ProductDetail;
