import React from "react";
import "./../assets/scss/errorpage.scss";

const ErrorPage = ({ msg }) => {
  return (
    <div className="error_page_wrapper">
      <h6>{msg}</h6>
    </div>
  );
};

export default ErrorPage;
