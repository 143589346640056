import React from "react";
import logo from "./../assets/svgs/logo.png";
import "./../assets/scss/splash.scss";
const Splash = () => {
  return (
    <div className="splash_wrapper">
      <img src={logo} alt="" />

      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Splash;
