import React, { createContext, useContext, useEffect, useState } from "react";
import api from "./../assets/axios/api";

const InitialDataContext = createContext();

const fetchColors = () => api.get("/colors");
const fetchCategories = () => api.get("/category");
const fetchBrands = () => api.get("/brand");

const InitialDataProvider = ({ children }) => {
  const [isInitialDataLoading, setIsInitialDataLoading] = useState(true);
  const [initialDataError, setInitialDataError] = useState(null);
  const [initialData, setInitialData] = useState(null);

  const [forceRender, setForceRender] =useState(false);
  useEffect(() => {
    const fetchDataParallel = async () => {
      setIsInitialDataLoading(true);
      try {
        const [colorsResponse, categoriesResponse, brandsResponse] =
          await Promise.all([fetchColors(), fetchCategories(), fetchBrands()]);

        const colors = colorsResponse.data;
        const categories = categoriesResponse.data;
        const brands = brandsResponse.data;

        setInitialData({ colors, categories, brands });
        setIsInitialDataLoading(false);
      } catch (error) {
        setInitialDataError("Something went wront not able to fetch app data");
        setIsInitialDataLoading(false);
      } 
    };
    fetchDataParallel();
  }, [forceRender]);

  return (
    <InitialDataContext.Provider
      value={{ isInitialDataLoading, initialData, initialDataError, setForceRender }}
    >
      {children}
    </InitialDataContext.Provider>
  );
};

export const useInitialDataContext = () => {
  return useContext(InitialDataContext);
};

export default InitialDataProvider;
