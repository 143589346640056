import React from "react";

import "./../assets/scss/main.scss";

import { useProductsContext } from "../context/ProductsProvider";

import Loader from "./utils/Loader";
import Error from "./utils/Error";
import NoDataFound from "./utils/NoDataFound";
// import { useProductIdContext } from "../context/ProductIdProvider";
import { useSearchParams } from "react-router-dom";
import { isFilterStr, productIdStr } from "../assets/js/variables";
import { calculateDiscountPercentage } from "../assets/js/calculateDiscountPercentage";

const Main = () => {
  const { isProductsLoading, productsError, productsData } =
    useProductsContext();

  let [searchParams, setSearchParams] = useSearchParams();

  const handleSetIsFilter = () => {
    searchParams.delete(isFilterStr);
    searchParams.append(isFilterStr, true);
    setSearchParams(searchParams);
  };

  if (isProductsLoading) {
    return (
      <main className="col-12 col-lg-9 col-xl-10">
        <div className="main_wrapper ">
          <div className="row">
            <div className="col-12">
              <Loader />
            </div>
          </div>
        </div>
      </main>
    );
  }

  if (productsError) {
    return (
      <main className="col-12 col-lg-9 col-xl-10">
        <div className="main_wrapper ">
          <div className="row">
            <div className="col-12">
              <Error msg={productsError} />
            </div>
          </div>
        </div>
      </main>
    );
  }

  if (productsData) {
    const { products } = productsData;
    // console.log(products[0]);
    if (!products.length) {
      return (
        <main className="col-12 col-lg-9 col-xl-10">
          <div className="main_wrapper ">
            <div className="row">
              <div className="col-12">
                <NoDataFound />
              </div>
            </div>
          </div>
        </main>
      );
    }
    return (
      <>
        <button
          className="filter_btn button_primary global_box_shadow"
          onClick={handleSetIsFilter}
        >
          FILTER
        </button>

        <main className="col-12 col-lg-9 col-xl-10">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-12">
                <div className="row g-3 p-2 p-sm-3">
                  {products.map((item, i) => (
                    <div className="col-6 col-md-4 col-xl-3" key={i}>
                      <div
                        className="product_card cursor"
                        onClick={() => {
                          searchParams.delete(productIdStr);
                          searchParams.append(productIdStr, item.variant._id);
                          setSearchParams(searchParams);
                        }}
                      >
                        <div
                          className="image_box"
                          style={{
                            backgroundImage: `url(${item.variant.images[0]})`,
                          }}
                        ></div>
                        <div className="content_box py-2 px-0 p-sm-2">
                          <p className="name">{item.title}</p>
                          <p>
                            <span className="price">
                              &#8377;{item.variant.discountedPrice}
                            </span>{" "}
                            <span className="mrp">
                              &#8377;{item.variant.price}
                            </span>{" "}
                            <span className="discount">{`(${calculateDiscountPercentage(
                              item.variant.price,
                              item.variant.discountedPrice
                            )}%OFF)`}</span>
                          </p>
                          <p className="retail_tag">
                            Retail Price:{" "}
                            <span>
                              &#8377;{item.variant.maxRetailSellingPrice} -
                              &#8377;{item.variant.minRetailSellingPrice}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
};

export default Main;
