import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "./../assets/svgs/logo.png";
import settingLogo from "./../assets/svgs/settingLogo.png";
import cartLogo from "./../assets/svgs/cartLogo.png";

import "./../assets/scss/header.scss";

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className="global_box_shadow">
      <div className="inner_nav">
        <div className="logo_box">
          <img src={logo} alt="" />
        </div>
        <div className="right_nav">
          <img
            src={cartLogo}
            alt=""
            onClick={() => {
              navigate("/demo");
            }}
            className="cursor"
          />
          <img
            src={settingLogo}
            alt=""
            onClick={() => {
              navigate("/demo");
            }}
            className="cursor"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
