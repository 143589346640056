import React from "react";

import Header from "./components/Header";
import Aside from "./components/Aside";
import Main from "./components/Main";
// import Footer from "./components/Footer";
import Splash from "./components/Splash";

import { useInitialDataContext } from "./context/InitialDataProvider";
import ErrorPage from "./components/ErrorPage";
import ProductDetail from "./components/ProductDetail";
import { useSearchParams } from "react-router-dom";
import { productIdStr } from "./assets/js/variables";

function App() {
  const { isInitialDataLoading, initialData, initialDataError } =
    useInitialDataContext();
  const [productIdParams] = useSearchParams();
  const productId = productIdParams.get(productIdStr);

  if (isInitialDataLoading) {
    return <Splash />;
  }

  if (initialDataError) {
    return <ErrorPage msg={initialDataError} />;
  }

  if (initialData)
    return (
      <>
        {productId ? <ProductDetail /> : null}
        <Header />
        <div className="app_wrapper p-2">
          <div className="row g-2">
            <Aside initialData={initialData} />
            <Main />
            {/* <Footer /> */}
          </div>
        </div>
      </>
    );
}

export default App;
