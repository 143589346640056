import React from "react";
import "./../../assets/scss/nodatafound.scss";
import NoDataLogo from "./../../assets/svgs/no-data.png";
import { useProductsContext } from "../../context/ProductsProvider";

const NoDataFound = ({ msg }) => {
  const { clearAllFilters } = useProductsContext();
  return (
    <div className="nodatafound_wrapper">
      <img src={NoDataLogo} alt="" />
      <h6>Not Found</h6>
      <button className="button_primary" onClick={clearAllFilters}>CLEAR ALL FILTERS</button>
    </div>
  );
};

export default NoDataFound;
