import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import api from "../assets/axios/api";
import { useInitialDataContext } from "./InitialDataProvider";

const ProductsContext = createContext();

const ProductsProvider = ({ children }) => {
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [productsError, setProductsDataError] = useState(null);
  const [productsData, setProductsData] = useState(null);

  const { setForceRender } = useInitialDataContext();

  const [query, setQuery] = useState({
    brands: [],
    categories: [],
    price: [],
    colors: [],
  });

  const addQueryWithType = (preData, name, type) => {
    const newBrands = [...preData[type], name];
    return { ...preData, [type]: newBrands };
  };

  const removeQueryWithType = (preData, name, type) => {
    const newBrand = preData[type].filter((item) => item !== name);
    return { ...preData, [type]: newBrand };
  };

  const setPriceInQuery = (preData, min, max) => {
    return { ...preData, price: [min, max] };
  };

  const clearAllFilters = () => {
    setQuery({
      brands: [],
      categories: [],
      price: [],
      colors: [],
    });
    setForceRender((preState) => !preState);
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    const fetchData = async () => {
      setIsProductsLoading(true);
      try {
        api
          .post("/products/all", query, {
            cancelToken: cancelTokenSource.token,
          })
          .then((response) => {
            setProductsData(response.data);
            setIsProductsLoading(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled
          console.log("Request canceled:", error.message);
        } else {
          setIsProductsLoading(false);
          setProductsDataError("Something went wront not able to fetch data");
        }
      }
    };
    fetchData();

    // Cleanup function to cancel the previous request when the component unmounts or when a new request is initiated
    return () =>
      cancelTokenSource.cancel(
        "Request canceled due to component unmount or new request"
      );
  }, [query]);

  return (
    <ProductsContext.Provider
      value={{
        isProductsLoading,
        productsError,
        productsData,
        //queries
        setQuery,
        //quries add function
        addQueryWithType,
        removeQueryWithType,
        setPriceInQuery,
        clearAllFilters,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => {
  return useContext(ProductsContext);
};

export default ProductsProvider;
