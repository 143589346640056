import React from "react";
import ReactDOM from "react-dom/client";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

// scss
import "./assets/scss/variables.scss";
import "./assets/scss/common.scss";
import "./assets/scss/index.scss";
import "./assets/scss/checkbox.scss";
import "./assets/scss/scrollbar.scss";

// components
import App from "./App";
import DemoPage from "./components/DemoPage";

// contexts
import InitialDataProvider from "./context/InitialDataProvider";
import ProductsProvider from "./context/ProductsProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/demo",
    element: <DemoPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <InitialDataProvider>
      <ProductsProvider>
        <RouterProvider router={router} />
      </ProductsProvider>
    </InitialDataProvider>
  </React.StrictMode>
);
