import React, { useEffect, useState } from "react";

import "./../assets/scss/aside.scss";
import RangeSelect from "./utils/RangeSelect";
import { useProductsContext } from "../context/ProductsProvider";
import { useSearchParams } from "react-router-dom";
import { isFilterStr } from "../assets/js/variables";

const Aside = ({ initialData }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let [searchParams, setSearchParams] = useSearchParams();
  const isFilter = searchParams.get(isFilterStr);
  const {
    //queries
    setQuery,
    //quries add function
    addQueryWithType,
    removeQueryWithType,
    setPriceInQuery,
  } = useProductsContext();
  const { categories, brands, colors } = initialData;

  const handleRemoveFilter = () => {
    searchParams.delete(isFilterStr);
    setSearchParams(searchParams);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <aside
      className={`filters_container_mob col-3 col-xl-2 ${
        !isFilter && windowWidth < 991.98 ? "dis_none" : "dis_flex"
      }`}
    >
      <div className="inner_aside">
        <div className="wrapper">
          <h6>CATEGORIES</h6>
          {categories.map((item, i) => {
            return (
              <div key={i} className="checkbox-wrapper">
                <label className="control control--checkbox">
                  {item.name}
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setQuery((preState) =>
                          addQueryWithType(preState, item.name, "categories")
                        );
                      } else {
                        setQuery((preState) =>
                          removeQueryWithType(preState, item.name, "categories")
                        );
                      }
                    }}
                  />
                  <div className="control__indicator"></div>
                </label>
              </div>
            );
          })}
        </div>
        <div className="wrapper">
          <h6>BRANDS</h6>
          {brands.map((item, i) => {
            return (
              <div key={i} className="checkbox-wrapper">
                <label className="control control--checkbox">
                  {item.name}
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setQuery((preState) =>
                          addQueryWithType(preState, item.name, "brands")
                        );
                      } else {
                        setQuery((preState) =>
                          removeQueryWithType(preState, item.name, "brands")
                        );
                      }
                    }}
                  />
                  <div className="control__indicator"></div>
                </label>
              </div>
            );
          })}
        </div>
        <div className="wrapper">
          <h6>PRICE</h6>
          {/* minVal, setMinVal */}
          <RangeSelect
            min={0}
            max={2000}
            // onChange={({ min, max }) =>
            //   console.log(`min = ${min}, max = ${max}`)
            //   // setQuery((preState) => setPriceInQuery(preState, min, max))}
            // }
            setQuery={setQuery}
            setPriceInQuery={setPriceInQuery}
          />
        </div>
        <div className="wrapper">
          <h6>COLORS</h6>
          {colors.map((item, i) => {
            return (
              <div key={i} className="checkbox-wrapper">
                <label className="control control--checkbox">
                  <span
                    className={`color_circle ${
                      item.name === "White" ? "border_grey" : ""
                    }`}
                    style={{ backgroundColor: item.code }}
                  ></span>
                  {item.name}
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setQuery((preState) =>
                          addQueryWithType(preState, item.name, "colors")
                        );
                      } else {
                        setQuery((preState) =>
                          removeQueryWithType(preState, item.name, "colors")
                        );
                      }
                    }}
                  />
                  <div className="control__indicator"></div>
                </label>
              </div>
            );
          })}
        </div>
        {/* <div className="wrapper apply_filter_btn_container">
          <button
            className="button_primary apply_filter_btn"
            onClick={handleRemoveFilter}
          >
            APPLY
          </button>
        </div> */}
      </div>
      <div className="inner_overlay"  onClick={handleRemoveFilter} ></div>
    </aside>
  );
};

export default Aside;
